
import Vue from 'vue'
import { isNotNested, parseData, parseForm } from '../../mixins/formData'

export default Vue.extend({
  name: 'ProjektEinreichen',

  data: () => {
    const formData: any = {}
    const project: any = {}
    return {
      dots: '.',
      errors: [],
      formData,
      formObj: {},
      interv: 0,
      isLoading: true,
      project,
      requiredFields: ['bedingungen'],
    };
  },

  computed: {
    awardConf () {
      return this.$store.state.currentAwardConf
    },
    baseUrl () {
      return this.$store.state.baseUrl
    },
    currentAward () {
      return this.$store.state.currentAward
    },
    currentPart () {
      return this.$route.params.part || this.$store.state.currentAwardConf.forms.user_submit.parts[0].key
    },
    id () {
      return this.$route.params.id;
    },
    isReady() {
      if (this.project && this.project.projectDetails) {
        const res = this.project?.projectDetails?.filter((pd: any) => {
          return pd.awardType === this.currentAward.awardType && pd.vollstaendig
        })
        const required = this.requiredFields.filter((name: string) => this.formData['part1/' + name] !== true)
        return res.length > 0 && required.length === 1 // 'eingereicht' is set on save, all other required fields need to be true
      }
      return false
    },
    projectCategories () {
      return this.$store.state.appInfo ? this.$store.state.appInfo.meta.projectCategories : null
    },
    formParts () {
      if (!this.$store.state.currentAwardConf) return null
      return this.$store.state.currentAwardConf.forms.user_submit.parts
    },
  },

  methods: {
    animateDots () {
      if (this.dots.length <= 4) {
        this.dots += '.';
      } else {
        this.dots = '.';
      }
    },

    initProject() {
      this.$store
        .dispatch('getProjectItem', this.$route.params.id)
        .then((project) => {
          this.project = project
          this.requiredFields = this.formParts[0].fields
            .map((f: any) => f.validation?.required ? f.name : null)
            .filter(Boolean)
          this.isLoading = false
        });
    },

    async saveProject() {
      this.isLoading = true
      this.interv = window.setInterval(this.animateDots, 200);
      const { awardType, year } = this.currentAward
      const formName = 'user_submit'
      let formData = this.parseForm(this.formParts, this.formData)[this.currentPart]
      if (formData.bedingungen === true) {
        formData.eingereicht = true
        let proj: any = {
          formData,
          formName,
          awardType,
          year,
          id: this.id,
          part: this.currentPart,
        }

        this.$store.dispatch('saveProject', proj).then((project) => {
          // console.log('saveProject -> project', project)
          this.isLoading = false
          this.$router.push({name: 'projektEingereicht', params: { id: project.id }})
        }).catch((err: Error) => {
          console.log('saveProject -> err', err)
          this.isLoading = false
          this.$store.commit('setAlert', {
            type: 'error',
            message: 'Fehler beim übermitteln! Bitte prüfen Sie die Eingaben.'
          })
        })
      }
      return false
    },

    isNotNested,
    parseData,
    parseForm,
  },

  mounted () {
    this.initProject();
  },

  destroyed () {
    if (this.interv) {
      clearInterval(this.interv)
    }
  },

});
